//──── SOCIAL SHARE HORIZONTAL ──────────────────────────────

.erpl_social-share-horizontal {
	display: flex;
	flex-wrap: wrap !important;

	a {
		height: 35px;
		width: 35px;
		@if ($darkMode) {
			border: 1px solid $gray-200;
			color: $white;
			background-color: $black;

		} @else { 
			border: 1px solid $gray-900;
			color: $icon-dark;
			background-color: $gray-201;

		}
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
	
		&:hover {
			background: $primary;
			@if ($darkMode) {
				color: $black;

			} @else { 
				color: $white;

			}
		}
		&:focus {		
			@if ($darkMode) {
			background: $yellow;
			color: $black;	
			}
			@else {
			background: $primary;
			color: $white;	
			}
		}
	}
}